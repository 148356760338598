import React, { useState, useRef, useEffect } from 'react';
import styles from './AnalyticsPage.module.css'; // Импорт стилей
import Calendar from './../../Calendar/Calendar';
import Table from './../../Table/Table';
import { getAppointmentsRequest, getStats } from './../../../api/requests';

function AnalyticsPage() {
  const [date, setDate] = useState(new Date())
  const [resNames, setResNames] = useState([])
  const [resData, setResData] = useState([])
  const [jobs, setJobs] = useState([])

  let setDateFunc = (val) => {
    setDate(val)
  }

  let fetchData = async () => {
    let resJobs = await getAppointmentsRequest()
    setJobs(resJobs)
    let res = await getStats(`${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`)
    setResNames(Object.keys(res));
    setResData(Object.values(res));
  }

  useEffect(() => {
    fetchData()
  }, [date]);

  return (
      <>
        <div className={styles.main_header}>
          <Calendar setDateFunc={setDateFunc} />
          <div className={styles.days_container}>
              <div id='top_1_index_0'>
                  <div className={styles.day_container} style={{borderLeft: '1px solid rgb(99, 102, 241)', borderRadius: '8px 0 0 8px'}}>У</div>
              </div>
              <div id='top_1_index_1'>
                  <div className={styles.day_container}>Д</div>
              </div>
              <div id='top_1_index_2'>
                  <div className={styles.day_container}>В</div>
              </div>
              <div id='top_1_index_3'>
                  <div className={styles.day_container}>Н</div>
              </div>
              <div id='top_1_index_4'>
                  <div className={styles.day_container}>Сум. часов</div>
              </div>
              <div id='top_1_index_5'>
                  <div className={styles.day_container}>ЗП. руб.</div>
              </div>
              <div id='top_1_index_6'>
                  <div className={styles.day_container}>Перер. ч.</div>
              </div>
              <div id='top_1_index_7'>
                  <div className={styles.day_container}>Перер. руб.</div>
              </div>
              <div id='top_1_index_8'>
                  <div className={styles.day_container} style={{borderRadius: '0 8px 8px 0'}}>ЗП</div>
              </div>
          </div>
        </div>
        <div className={styles.main_content}>
          {resData.length > 0 && resData.map((el, index) => {
            return (
              <Table index={index} key={resNames[index]} JobTitleName={resNames[index]} leftPart={el} topPart={jobs.find((job) => job.name === resNames[index]).hourrates} widthPart="117px" tableNumber='1'/>
            )
          })}
        </div>
      </>
  );
}

export default AnalyticsPage;
