import show_password from "../../../assets/LoginPage/show_password.svg";
import hide_password from "../../../assets/LoginPage/hide_password.svg";
import { useState } from "react";
import styles from './PasswordPage.module.css'
import { changePassword } from "../../../api/requests";
import { useEffect } from "react";

function PasswordPage() {
  let [error, setError] = useState('')
  let [firstPassword, setFirstPassword] = useState('')
  let [secondPassword, setSecondPassword] = useState('')
  let [showPasswordFirst, setShowPasswordFirst] = useState(false)
  let [showPasswordSecond, setShowPasswordSecond] = useState(false)

  let saveNewPassword = async () => {
    if(!firstPassword || !secondPassword) {
      setError('Заполните все поля')
    } else {
      await changePassword({
        password: secondPassword,
        prevpassword: firstPassword
    })
    }
  }

  useEffect(() => {
    setError('')
  }, [firstPassword, secondPassword]);

  return (
    <>
    <div className={styles.password_container}>
        <div className={styles.input_container}>
            <span>Старый пароль</span>
            <input value={firstPassword} onChange={(e) => setFirstPassword(e.target.value)} type={showPasswordFirst ? 'text' : 'password'} placeholder="Введите пароль"/>
            <img onClick={() => {setShowPasswordFirst(!showPasswordFirst)}} style={{cursor: 'pointer'}} src={showPasswordFirst ? show_password : hide_password} alt="password" />
        </div>
        <div className={styles.input_container}>
            <span>Новый пароль</span>
            <input value={secondPassword} onChange={(e) => setSecondPassword(e.target.value)} type={showPasswordSecond ? 'text' : 'password'} placeholder="Введите пароль"/>
            <img onClick={() => {setShowPasswordSecond(!showPasswordSecond)}} style={{cursor: 'pointer'}} src={showPasswordSecond ? show_password : hide_password} alt="password" />
        </div>
        <button onClick={saveNewPassword}>
            Изменить пароль
        </button>
    </div>
    {error && <div className={styles.error_container}>
        {error}
      </div>
    }
    </>
  );
}

export default PasswordPage;
