import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainPage from './pages/MainPage/MainPage';
import LoginPage from './pages/LoginPage/LoginPage';
import Header from './components/Header/Header';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import EmployeesPage from './pages/EmployeesPage/EmployeesPage';
import StatisticsPage from './pages/StatisticsPage/StatisticsPage';
import styles from './App.module.css';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const isAuthorized = () => {
    return !!(localStorage.getItem('access_token') && localStorage.getItem('refresh_token'));
  };

  useEffect(() => {
    let isMounted = true; // Флаг для предотвращения обновления состояния, если компонент размонтирован.

    const fetchData = async () => {
      if (location.pathname.includes('/login')) {
        setIsLoading(false); // Если мы уже на странице логина, загрузка завершается.
        return;
      }

      if (!isAuthorized()) {
        navigate('/login');
        setIsLoading(false);
        return;
      }

      if (isMounted) {
        setIsLoading(false); // Устанавливаем загрузку в false только если компонент еще монтирован.
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Устанавливаем флаг при размонтировании компонента.
    };
  }, [navigate]);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <>
      {!location.pathname.includes('/login') && <Header />}
      <div className={styles.App}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/statistics/*" element={<StatisticsPage />} />
          <Route path="/login/*" element={<LoginPage />} />
          <Route path="/contact" element={<LoginPage />} />
          <Route path="/employees/*" element={<EmployeesPage />} />
          <Route path="/settings/*" element={<SettingsPage />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
