import axios from 'axios';

// Создаем экземпляр axios
const axiosInstance = axios.create({
  baseURL: 'https://api.k-system-k.ru',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Функция для получения токенов из локального хранилища
const getRefreshToken = () => localStorage.getItem('refresh_token');

// Добавляем интерцептор для обработки ошибок
axiosInstance.interceptors.response.use(
  (response) => response, // Успешный ответ
  async (error) => {
    // Проверяем, нужно ли пропустить обработку
    if (error.config && error.config.skipAuthInterceptor) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      if (window.location.pathname === '/login') {
        return Promise.reject(error);
      }

      try {
        const refreshToken = getRefreshToken();
        const refreshResponse = await axiosInstance.get('/auth/refresh', {
          headers: { Authorization: `Bearer ${refreshToken}` },
        });

        localStorage.setItem('access_token', refreshResponse.data.accessToken);
        localStorage.setItem('refresh_token', refreshResponse.data.refreshToken);

        axiosInstance.defaults.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;

        const originalRequest = error.config;
        originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Ошибка обновления токена:', refreshError);
        window.location.href = '/login';
        window.location.reload();

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);


export default axiosInstance;
