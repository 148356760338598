import styles from './Table.module.css'
import job_title_arrow from '../../assets/Table/job_title_arrow.svg'
import { ReactComponent as TitlePlus } from '../../assets/Table/title_plus.svg';
import { useEffect, useRef, useState } from 'react'
import AddEmployeeModal from '../MainPage/AddEmployeeModal/AddEmployeeModal';
import ChangeDayStatus from '../MainPage/ChangeDayStatus/ChangeDayStatus.jsx';
import CreateJobTitleModal from './../MainPage/CreateJobTitleModal/CreateJobTitleModal';
import { createDayStatusWorker, updateDayStatusWorker } from '../../api/requests.js';
import CloseDayModal from '../MainPage/CloseDayModal/CloseDayModal.jsx';

function Table({index, JobTitleName, leftPart, topPart, widthPart, canAddJobTitle = false, date, tableNumber, updateStatus, appointmentsdict, hourNorms, jobs, updateData, closedDay}) {
    console.log(closedDay)
    const [hideList, setHideList] = useState(false)
    const [dayModal, setDayModal] = useState(false)
    const [dayForModal, setDayForModal] = useState(0)
    const [closeDayModal, setCloseDayModal] = useState(false)
    const [activeChangeStatus, setActiveChangeStatus] = useState({workerId: '', index: ''})
    const [currentDayStatus, setCurrentDayStatus] = useState(false)
    const [addEmployeeModal, setAddEmployeeModal] = useState(false)
    const [showCreateJobTitleModal, setShowCreateJobTitleModal] = useState(false)
    const [topArray, setTopArray] = useState(false)
    const [headerForStatistics, setHeaderForStatistics] = useState(false)
    const jobTitleContainerRef = useRef(null);

    const fillArrayForIndividualWorkerStatisctics = (workerData, index) => {
        // Преобразуем объект в массив для доступа по индексу
        const workersArray = Object.entries(workerData);
    
        // Проверяем наличие работника по индексу
        if (index < 0 || index >= workersArray.length) {
            console.warn(`Worker with index ${index} not found`);
            return [];
        }
    
        // Создаем массив статистики
        const statisticsArray = [
            {
                first: workerData.morning.basehours,
                second: workerData.morning.excesshours,
                type: 'ч.',
            },
            {
                first: workerData.day.basehours,
                second: workerData.day.excesshours,
                type: 'ч.',
            },
            {
                first: workerData.evening.basehours,
                second: workerData.evening.excesshours,
                type: 'ч.',
            },
            {
                first: workerData.night.basehours,
                second: workerData.night.excesshours,
                type: 'ч.',
            },
            {
                first: workerData.hoursum,
                type: 'ч.',
            },
            {
                first: workerData.basesalary.toLocaleString('ru-RU'),
                type: 'руб.',
            },
            {
                first: workerData.excesshours,
                type: 'ч.',
            },
            {
                first: workerData.excesscost.toLocaleString('ru-RU'),
                type: 'руб.',
            },
            {
                first: workerData.totalsalary.toLocaleString('ru-RU'),
                type: 'руб.',
            },
        ];
    
        return statisticsArray;
    };

    const fillArrayForIndividualWorker = (workerDates, referenceArray, index) => {
        const totalDays = referenceArray ? referenceArray.length : 9; // Количество дней в месяце
        const alignedArray = Array(totalDays).fill({}); // Инициализация массива пустыми объектами
    
        if (canAddJobTitle) {
            workerDates.forEach((item) => {
                if (item.day > 0 && item.day <= totalDays) {
                    alignedArray[item.day - 1] = item; // Заполняем только для соответствующих дней
                }
            });
        } else {
            // Формирование статистики для работника
            const totalSums = Object.values(leftPart).reduce(
                (totals, person) => {
                    totals.nightBaseHours += person.night.basehours;
                    totals.nightExcessHours += person.night.excesshours;
            
                    totals.morningBaseHours += person.morning.basehours;
                    totals.morningExcessHours += person.morning.excesshours;
            
                    totals.dayBaseHours += person.day.basehours;
                    totals.dayExcessHours += person.day.excesshours;
            
                    totals.eveningBaseHours += person.evening.basehours;
                    totals.eveningExcessHours += person.evening.excesshours;
            
                    totals.hoursum += person.hoursum;
                    totals.basesalary += person.basesalary;
                    totals.excesshours += person.excesshours;
                    totals.excesscost += person.excesscost;
                    totals.totalsalary += person.totalsalary;
            
                    return totals;
                },
                {
                    nightBaseHours: 0,
                    nightExcessHours: 0,
                    morningBaseHours: 0,
                    morningExcessHours: 0,
                    dayBaseHours: 0,
                    dayExcessHours: 0,
                    eveningBaseHours: 0,
                    eveningExcessHours: 0,
                    hoursum: 0,
                    basesalary: 0,
                    excesshours: 0,
                    excesscost: 0,
                    totalsalary: 0
                }
            );

            let statisticsArray = [
                {
                    first: totalSums.morningBaseHours,
                    second: totalSums.morningExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.dayBaseHours,
                    second: totalSums.dayExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.eveningBaseHours,
                    second: totalSums.eveningExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.nightBaseHours,
                    second: totalSums.nightExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.hoursum,
                    type: 'ч.'
                },
                {
                    first: totalSums.basesalary.toLocaleString('ru-RU'),
                    type: 'руб.'
                },
                {
                    first: totalSums.excesshours,
                    type: 'ч.'
                },
                {
                    first: totalSums.excesscost.toLocaleString('ru-RU'),
                    type: 'руб.'
                },
                {
                    first: totalSums.totalsalary.toLocaleString('ru-RU'),
                    type: 'руб.'
                }
            ]

            return statisticsArray; // Возвращаем массив статистики
        }
    
        return alignedArray;
    };

    function getDaysArrayInCurrentMonth() {
        // Получаем текущую дату
        const now = new Date();
      
        // Получаем текущий год и месяц
        const year = now.getFullYear();
        const month = now.getMonth(); // Месяцы в JavaScript начинаются с 0
      
        // Получаем количество дней в текущем месяце
        const daysInMonth = new Date(year, month + 1, 0).getDate();
      
        // Создаем массив с днями
        const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
      
        return daysArray;
    }

    const renderStatus = (item) => {
        switch (item.type) {
            case 'HLD':
                return <span style={{ color: 'white', background: 'rgb(241, 99, 101)' }}>О</span>;
            case 'SCK':
                return <span style={{ color: 'white', background: 'rgb(241, 99, 101)' }}>Б</span>;
            case 'multiple':
            case 'simple':
                // Вложенный switch для 'multiple'
                switch (item.parts[0]?.type) {
                    case 'morning':
                        return <span style={{ color: 'rgb(17, 25, 39)', background: 'rgb(255, 220, 172)' }}>У {item.parts.length > 1 && <p>+ {item.parts.length - 1}</p>}</span>;
                    case 'day':
                        return <span style={{ color: 'rgb(17, 25, 39)', background: 'rgb(255, 249, 187)' }}>Д {item.parts.length > 1 && <p>+ {item.parts.length - 1}</p>}</span>;
                    case 'evening':
                        return <span style={{ color: 'rgb(17, 25, 39)', background: 'rgb(234, 234, 236)' }}>В {item.parts.length > 1 && <p>+ {item.parts.length - 1}</p>}</span>;
                    case 'night':
                        return <span style={{ color: 'white', background: 'rgb(138, 138, 138)' }}>Н {item.parts.length > 1 && <p>+ {item.parts.length - 1}</p>}</span>;
                    default:
                        return; // Если дополнительный тип не найден
                }
            default:
                return null;
        }
    };

    const handleStatusUpdate = (workerId, day, newStatus, updatedPart, newPart = null) => {
        updateStatus(
            JobTitleName,
            workerId,
            day,
            newStatus,
            updatedPart,
            newPart 
        )
        
    };

    // Возможные статусы по клавишам
    const statusMapping = {
        о: { type: 'HLD' }, // Например, статус "выходной"
        б: { type: 'SCK' }, // "Больничный"
        у: { type: 'multiple' }, // "Утро"
        д: { type: 'multiple' }, // "День"
        н: { type: 'multiple' }, // "Ночь"
        в: { type: 'multiple' } // "Вечер"
    };

    const activeChangeStatusRef = useRef(activeChangeStatus);

    // Функция для обработки нажатий клавиш
    const handleKeyPress = async (event) => {
        if(!event.key) return
        const pressedKey = event.key.toLowerCase();
        const currentStatus = activeChangeStatusRef.current; // Немедленный доступ
    
        if(!currentStatus.workerId) {
            return
        }

        if (statusMapping[pressedKey]) {
            let type, from, to, count;
        
            if (pressedKey === 'у') {
                type = "morning";
                from = hourNorms.morning.from;
                to = hourNorms.morning.to;
                count = hourNorms.morning.count;
            } else if (pressedKey === 'д') {
                type = "day";
                from = hourNorms.day.from;
                to = hourNorms.day.to;
                count = hourNorms.day.count;
            } else if (pressedKey === 'в') {
                type = "evening";
                from = hourNorms.evening.from;
                to = hourNorms.evening.to;
                count = hourNorms.evening.count;
            } else if (pressedKey === 'н') {
                type = "night";
                from = hourNorms.night.from;
                to = hourNorms.night.to;
                count = hourNorms.night.count;
            }
        
            if (currentStatus.workerId) {
                handleStatusUpdate(
                    currentStatus.workerId,
                    currentStatus.index + 1,
                    statusMapping[pressedKey],
                    null,
                    pressedKey !== 'о' && pressedKey !== 'б'
                        ? [{ type, from, to, count }]
                        : null
                );
            }
        
            const parts = pressedKey !== 'о' && pressedKey !== 'б'
                ? [
                    {
                        type,
                        from,
                        to,
                        count,
                    },
                ]
                : [
                    {
                        type: pressedKey === 'о' ? 'HLD' : 'SCK',
                    },
                ];
        
            const data = {
                day: currentStatus.index + 1,
                month: date.getMonth() + 1,
                year: date.getFullYear(),
                workerid: currentStatus.workerId,
                appointmentid: appointmentsdict,
                parts, // Передаем массив
            };
        
            let dates = workersWithUpdatedDays.find((el) => el.id == currentStatus.workerId)?.dates

            let res;
            if(dates?.some((day) => day.day == currentStatus.index + 1)) {
                res = await updateDayStatusWorker(data, `${String(currentStatus.index + 1).padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`);
            } else {
                res = await createDayStatusWorker(data, `${String(currentStatus.index + 1).padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`);
            }

            handleClick('', ''); // Сбрасываем активный статус
        } else {
            handleClick('', '');
        }
    };
    
    const handleClick = (workerId, index, name) => {
        const newStatus = { workerId, index, name };
        setActiveChangeStatus(newStatus); // Обновляем состояние
    };

    useEffect(() => {
        if (index !== 0) {
            setHideList(true)
        }
        if(!canAddJobTitle) {
            const totalSums = Object.values(leftPart).reduce(
                (totals, person) => {
                    totals.nightBaseHours += person.night.basehours;
                    totals.nightExcessHours += person.night.excesshours;
            
                    totals.morningBaseHours += person.morning.basehours;
                    totals.morningExcessHours += person.morning.excesshours;
            
                    totals.dayBaseHours += person.day.basehours;
                    totals.dayExcessHours += person.day.excesshours;
            
                    totals.eveningBaseHours += person.evening.basehours;
                    totals.eveningExcessHours += person.evening.excesshours;
            
                    totals.hoursum += person.hoursum;
                    totals.basesalary += person.basesalary;
                    totals.excesshours += person.excesshours;
                    totals.excesscost += person.excesscost;
                    totals.totalsalary += person.totalsalary;
            
                    return totals;
                },
                {
                    nightBaseHours: 0,
                    nightExcessHours: 0,
                    morningBaseHours: 0,
                    morningExcessHours: 0,
                    dayBaseHours: 0,
                    dayExcessHours: 0,
                    eveningBaseHours: 0,
                    eveningExcessHours: 0,
                    hoursum: 0,
                    basesalary: 0,
                    excesshours: 0,
                    excesscost: 0,
                    totalsalary: 0
                }
            );

            let statisticsArray = [
                {
                    first: totalSums.morningBaseHours,
                    second: totalSums.morningExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.dayBaseHours,
                    second: totalSums.dayExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.eveningBaseHours,
                    second: totalSums.eveningExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.nightBaseHours,
                    second: totalSums.nightExcessHours,
                    type: 'ч.'
                },
                {
                    first: totalSums.hoursum,
                    type: 'ч.'
                },
                {
                    first: totalSums.basesalary.toLocaleString('ru-RU'),
                    type: 'руб.'
                },
                {
                    first: totalSums.excesshours,
                    type: 'ч.'
                },
                {
                    first: totalSums.excesscost.toLocaleString('ru-RU'),
                    type: 'руб.'
                },
                {
                    first: totalSums.totalsalary.toLocaleString('ru-RU'),
                    type: 'руб.'
                }
            ]
            setHeaderForStatistics(statisticsArray)
        } else {
            // Массив дней для таблицы главной
            let topArray = date ? getDaysArrayInCurrentMonth() : topPart ? topPart : null;
            setTopArray(topArray)
        }
    }, []);

    useEffect(() => {
        // Подписываемся на события клавиатуры
        const keyPressHandler = (event) => handleKeyPress(event);
        window.addEventListener('keydown', keyPressHandler);
        activeChangeStatusRef.current = activeChangeStatus;
        
        return () => {
            // Удаляем обработчик при размонтировании
            window.removeEventListener('keydown', keyPressHandler);
        };
    }, [activeChangeStatus]);

    // Обновляем `updatedArray` для каждого работника отдельно
    let workersWithUpdatedDays
    if(canAddJobTitle && leftPart) {
        const valuesArray = Object.values(leftPart);
        workersWithUpdatedDays = valuesArray.map((value) => ({
            ...value,
            updatedDates: fillArrayForIndividualWorker(value.dates, topArray),
        }));
    } else if(leftPart) {
        const valuesKeys = Object.keys(leftPart);
        const valuesArray = Object.values(leftPart);
        workersWithUpdatedDays = valuesArray.map((value, index) => ({
            ...value,
            name: valuesKeys[index],
            updatedDates: fillArrayForIndividualWorkerStatisctics(value, index),
        }));
    } else {
        workersWithUpdatedDays = []
    }

    return (
        <div className={styles.table_container} style={{maxHeight: hideList ? `${jobTitleContainerRef.current.offsetHeight}px` : '', overflowX: hideList ? `hidden` : ''}}>
            <div className={styles.top_part_container}>
                <div className={styles.job_title_container}>
                    <span ref={jobTitleContainerRef} onClick={() => {setHideList(!hideList)}} style={{width: canAddJobTitle ? '150px' : '225px', maxWidth: canAddJobTitle ? '150px' : '225px'}}>
                        {JobTitleName}
                        <img src={job_title_arrow} style={{rotate: hideList ? '270deg' : ''}} alt="" />
                    </span>
                    {canAddJobTitle && <div className={styles.table_plus} style={{color: '#6366F1'}} onClick={() => setShowCreateJobTitleModal(!showCreateJobTitleModal)}><TitlePlus /></div>}
                </div>

                <div className={styles.top_part_list}>
                    {/* Для статистики */}
                    {hideList && !topArray && <div className={styles.top_part_container_statistics}>
                        {headerForStatistics.map((el, index) => {
                            const isFirst = index === 0; // Первый элемент
                            const isLast = index === headerForStatistics.length - 1; // Последний элемент

                            return (
                                <div 
                                    className={styles.top_part_elem_statistics} 
                                    style={{
                                        width: widthPart,
                                        borderRadius: `${isFirst ? '10px 0 0 10px' : ''} ${isLast ? '0 10px 10px 0' : ''}`
                                    }}
                                    key={index}
                                >
                                    {el.first} {el.type} {el.second > 0 && `+ ${el.second} ${el.type}`}
                                </div>
                            );
                        })}
                    </div>}
                    {/* Для статистики */}
                    {/* Для главной */}
                    {!hideList && topArray && topArray.map((el, index) => {
                        return (
                            <div style={{width: widthPart}} className={styles.top_list_item} key={index} id={`top_${tableNumber}_index_${index}`}>
                                <span className={styles.top_day_active} style={{color: el < closedDay + 1 && 'white', background: el < closedDay + 1 && 'rgb(99, 102, 241)'}} onClick={() => {if(el > closedDay){setDayForModal(el); setCloseDayModal(true)}}}>
                                    {el}
                                </span>
                            </div>
                        )
                    })}
                    {/* Для главной */}
                </div>
            </div>
            <div className={styles.bottom_part_container}>
                {workersWithUpdatedDays.map((worker, workerIndex) => (
                    <div className={styles.left_part} key={workerIndex}>
                        <div className={styles.name_container}>
                            <img src="" alt="" />
                            <span>
                                {worker.lastname} {worker.name} {worker.secondname}
                            </span>
                        </div>
                        <div className={styles.data_container}>
                            {worker.updatedDates.map((item, index) => (
                                <div
                                    key={`${workerIndex}_${index}`}
                                    className={styles.bottom_part_elem}
                                    style={{
                                        width: widthPart,
                                        height: '40px',
                                        borderRadius: index === 0 ? '10px 0 0 10px' : index + 1 === worker.updatedDates.length ? '0 10px 10px 0' : '',
                                        borderRight: index + 1 === worker.updatedDates.length ? '1px solid #6366F1' : '',
                                        cursor: index < closedDay && 'not-allowed',
                                    }}
                                    onMouseEnter={() => {if(document.getElementById(`top_${tableNumber}_index_${index}`))document.getElementById(`top_${tableNumber}_index_${index}`).style.background = "rgb(242, 249, 255)"}}
                                    onMouseLeave={() => {if(document.getElementById(`top_${tableNumber}_index_${index}`))document.getElementById(`top_${tableNumber}_index_${index}`).style.background = "white"}}
                                    onClick={() => {if(canAddJobTitle && index > closedDay - 1){setActiveChangeStatus({ workerId: worker.id, index, name: `${worker.lastname} ${worker.name} ${worker.secondname}` })}}}
                                    onDoubleClick={() => {
                                        if (canAddJobTitle && index > closedDay - 1) {
                                            setCurrentDayStatus({ ...item, date, workerId: activeChangeStatus }); // Объединяем данные `item` и `date`
                                            setDayModal(true); // Мгновенно отображаем модальное окно
                                            setActiveChangeStatus({workerId: '', index: ''})
                                        }
                                    }}
                                >
                                    {activeChangeStatus.workerId === worker.id && activeChangeStatus.index === index && !dayModal ? '|' : renderStatus(item)}
                                    {!canAddJobTitle && (
                                        <div style={{display: 'flex'}}>
                                            {item.first} {item.type} {item.second > 0 && <span style={{width: 'auto', color: 'red'}}>+ {item.second} {item.type}</span>}
                                        </div>
                                    )}

                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                {canAddJobTitle && <div onClick={() => setAddEmployeeModal(!addEmployeeModal)} className={styles.botton_add} style={{color: '#A4B9D0'}}>
                    Добавить 
                    <TitlePlus />
                </div>}
            </div>
            {canAddJobTitle &&
            <>
                <CloseDayModal seeModal={closeDayModal} setAddEmployeeModal={() => setCloseDayModal(!closeDayModal)} date={date} day={dayForModal}/>
                <AddEmployeeModal seeModal={addEmployeeModal} setAddEmployeeModal={() => setAddEmployeeModal(!addEmployeeModal)} jobsFromMain={jobs} updateData={updateData}/>
                <CreateJobTitleModal showCreateJobTitleModal={showCreateJobTitleModal} setShowCreateJobTitleModal={setShowCreateJobTitleModal} jobs={jobs} updateData={updateData}/>
                <ChangeDayStatus seeModal={dayModal} setChangeDayStatus={() => setDayModal(!dayModal)} currentDayStatus={currentDayStatus} handleStatusUpdate={handleStatusUpdate} appointmentsdict={appointmentsdict} workersWithUpdatedDays={workersWithUpdatedDays} hourNorms={hourNorms}/>
            </>}
        </div>
    )
}

export default Table;
  