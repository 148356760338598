import React, { useState, useRef, useEffect } from 'react';
import styles from './MainPage.module.css'; // Импорт стилей
import Calendar from './../../components/Calendar/Calendar.jsx';
import filter_icon from '../../assets/MainPage/filter_icon.svg'
import Table from '../../components/Table/Table.jsx';
import useClickOutside from '../../components/UseClickOutside/UseClickOutside.jsx';
import JobTitle from '../../components/EmployeesPage/JobTitle/JobTitle.jsx';
import { getAppointmentsRequest, getStatusesByMonth } from '../../api/requests.js';

function MainPage() {
  const [date, setDate] = useState(new Date());
  const [dayStatusFilter, setDayStatusFilter] = useState([]);
  const [closedDay, setClosedDay] = useState(0);
  const [nameFilter, setNameFilter] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [choosedJob, setChoosedJob] = useState([]);
  const [appointmets, setAppointmets] = useState([]);
  const [appointmetsForFilters, setAppointmetsForFilters] = useState([]);
  const [appointmentKeys, setAppointmentKeys] = useState([]);
  const [hourNorms, setHourNorms] = useState({
    night: {
      from: "00:00",
      to: "04:00"
    },
    morning: {
        from: "04:00",
        to: "12:00"
    },
    day: {
        from: "12:00",
        to: "18:00"
    },
    evening: {
        from: "18:00",
        to: "00:00"
    }
  });
  const [jobs, setJobs] = useState([
    { id: 0, name: 'Директор' },
    { id: 1, name: 'Работник' },
    { id: 2, name: 'Повар' },
  ]);

  let getAllData = async () => {
    let res = await getAppointmentsRequest();
    setJobs(res);
    
    let statuses = await getStatusesByMonth(`${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`);
    console.log(statuses)
    if(statuses.closed.length > 1) {
      setClosedDay(statuses.closed[statuses.closed.length - 1])
    }
    setAppointmentKeys(Object.keys(statuses.appointments));
    setAppointmets(Object.values(statuses.appointments))
    setAppointmetsForFilters(statuses.appointments)
    
    const hourKeys = ['night', 'morning', 'day', 'evening']; // Ключи в нужном порядке

    // Преобразование массива в объект
    const updatedHourNorms = statuses.hournorms.reduce((acc, cur, index) => {
      if (hourKeys[index]) {
        acc[hourKeys[index]] = { from: cur.from, to: cur.to, count: +cur.norm };
      }
      return acc;
    }, {});

    setHourNorms(updatedHourNorms);
  } 

  useEffect(() => {
    getAllData();
  }, [date]);

  let updateStatus = (jobTitle, workerId, day, newStatus, updatedPart, addNewPart) => {
    const jobIndex = appointmentKeys.indexOf(jobTitle); // Найти индекс jobTitle в appointmentKeys
    if (jobIndex === -1) {
      console.error(`Job title "${jobTitle}" не найден в appointmentKeys`);
      return;
    }
  
    setAppointmets((prevAppointments) =>
      prevAppointments.map((jobStatus, index) => {
        if (index !== jobIndex) return jobStatus; // Пропускаем другие должности
  
        // Найти запись с указанным workerId
        const workerKey = Object.keys(jobStatus).find(
          (key) => jobStatus[key]?.id === workerId
        );
  
        if (!workerKey) return jobStatus; // Если работник не найден, вернуть без изменений
  
        const workerData = jobStatus[workerKey];
  
        // Обновить даты
        const updatedDates = workerData.dates.some((date) => date.day === day)
          ? workerData.dates.map((date) =>
              date.day === day
                ? {
                    ...date,
                    type: newStatus?.type || date.type,
                    parts: updatedPart
                      ? [
                          ...date.parts.map((part) => ({ ...part })), // Копировать текущие части
                          ...updatedPart, // Добавить обновленные части
                        ]
                      : addNewPart !== undefined
                      ? addNewPart // Новый массив частей, если его нет
                      : [...date.parts], // Оставить текущие части, если изменений нет
                  }
                : date
            )
          : [
              ...workerData.dates,
              {
                day,
                type: newStatus?.type,
                parts: addNewPart || [], // Если данных нет для дня, создать с новыми частями
              },
            ];
  
        // Вернуть обновленный объект с изменениями для найденного работника
        return {
          ...jobStatus,
          [workerKey]: {
            ...workerData,
            dates: updatedDates,
          },
        };
      })
    );
  };

  useEffect(() => {
    let filteredEmployees = Object.values(appointmetsForFilters);
    let filteredKeys = Object.keys(appointmetsForFilters);
  
    if (choosedJob.length !== 0) {
      // Массив для хранения индексов, которые нужно оставить
      let arrayKeys = [];
  
      // Фильтрация filteredEmployees
      filteredEmployees = filteredEmployees.filter((employee, index) => {
        // Проверяем, если текущая работа совпадает с одним из выбранных
        if (choosedJob.some((job) => job.name == filteredKeys[index])) {
          arrayKeys.push(index);  // Запоминаем индекс
          return true; // Оставляем этот элемент
        }
        return false; // Не оставляем этот элемент
      });
  
      // Фильтруем filteredKeys на основе arrayKeys
      filteredKeys = filteredKeys.filter((_, index) => arrayKeys.includes(index));
    }

    if (dayStatusFilter.length !== 0) {
      filteredEmployees = filteredEmployees.map(employee => {
        // Проверяем совпадение имени
        const filteredEmployee = Object.entries(employee).reduce((acc, [key, value]) => {
          dayStatusFilter.forEach((el) => {
            if(el === 'O' && value.dates.some((day) => day.type === "HLD")) {
              acc[key] = value;
            } else if(el === 'B' && value.dates.some((day) => day.type === "SCK")) {
              acc[key] = value;
            } else if(el === 'D' && value.dates.some((day) => day.parts?.some((part) => part.type === 'day'))) {
              acc[key] = value;
            } else if(el === 'U' && value.dates.some((day) => day.parts?.some((part) => part.type === 'morning'))) {
              acc[key] = value;
            } else if(el === 'V' && value.dates.some((day) => day.parts?.some((part) => part.type === 'evening'))) {
              acc[key] = value;
            } else if(el === 'N' && value.dates.some((day) => day.parts?.some((part) => part.type === 'night'))) {
              acc[key] = value;
            }
          })

          return acc; // Возвращаем аккумулированный объект
        }, {});
  
        // Возвращаем только объекты с совпадениями, игнорируем пустые
        return Object.keys(filteredEmployee).length > 0 ? filteredEmployee : {};
      });
    }
  
    if (nameFilter) {
      filteredEmployees = filteredEmployees.map(employee => {
        // Проверяем совпадение имени
        const filteredEmployee = Object.entries(employee).reduce((acc, [key, value]) => {
          if (key.toLowerCase().includes(nameFilter.toLowerCase())) {
            acc[key] = value; // Сохраняем объект, если ключ совпадает
          }
          return acc; // Возвращаем аккумулированный объект
        }, {});
  
        // Возвращаем только объекты с совпадениями, игнорируем пустые
        return Object.keys(filteredEmployee).length > 0 ? filteredEmployee : {};
      });
    }
  
    // Удаляем из массива `null` значения
    // filteredEmployees = filteredEmployees.filter(Boolean);
  
    setAppointmets(filteredEmployees);
    setAppointmentKeys(filteredKeys);
  }, [nameFilter, dayStatusFilter, choosedJob]);
  
  
  const addJob = (val) => {
    setChoosedJob((prevChoosedJob) => 
      prevChoosedJob.includes(val) 
        ? prevChoosedJob.filter((el) => el !== val) 
        : [...prevChoosedJob, val]
    );
  };

  const modalFilterRef = useRef();
  useClickOutside(modalFilterRef, () => {if(showFilterModal){setShowFilterModal(false)}});

  let setDateFunc = (val) => {
    setDate(val)
  }

  let handleDayStatusFilter = (dayStatus) => {
    if (dayStatusFilter.includes(dayStatus)) {
      const newArray = dayStatusFilter.filter((status) => status !== dayStatus);
      setDayStatusFilter(newArray);
    } else {
      const newArray = [...dayStatusFilter, dayStatus];
      setDayStatusFilter(newArray);
    }
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.main_header}>
        <Calendar setDateFunc={setDateFunc} />
        <div ref={modalFilterRef} className={styles.main_header_filter} onClick={() => setShowFilterModal(true)}>
          <img src={filter_icon} alt=""/>
          {showFilterModal && <div className={styles.modal_filter_container}>
            {/* <div className={styles.modal_filter_filt} onClick={() => setCurrentFilter('default')} style={{color: currentFilter === 'default' && 'rgb(99, 102, 241)'}}>
              По умолчанию
            </div>
            <hr style={{margin: "9px   0"}}/>
            <div className={styles.modal_filter_filt} onClick={() => setCurrentFilter('date')} style={{color: currentFilter === 'date' && 'rgb(99, 102, 241)'}}>
              По дате
            </div> */}
            <hr style={{margin: "9px 0"}}/>
            <div className={styles.modal_filter_status}>
              По статусу
              <div className={styles.modal_filter_statuses}>
                <div className={styles.modal_status_container} onClick={() => handleDayStatusFilter('D')} style={{color: dayStatusFilter.includes("D") && 'rgb(99, 102, 241)', border: dayStatusFilter.includes("D") && '1px solid rgb(99, 102, 241)'}}>Д</div>
                <div className={styles.modal_status_container} onClick={() => handleDayStatusFilter('U')} style={{color: dayStatusFilter.includes("U") && 'rgb(99, 102, 241)', border: dayStatusFilter.includes("U") && '1px solid rgb(99, 102, 241)'}}>У</div>
                <div className={styles.modal_status_container} onClick={() => handleDayStatusFilter('V')} style={{color: dayStatusFilter.includes("V") && 'rgb(99, 102, 241)', border: dayStatusFilter.includes("V") && '1px solid rgb(99, 102, 241)'}}>В</div>
                <div className={styles.modal_status_container} onClick={() => handleDayStatusFilter('N')} style={{color: dayStatusFilter.includes("N") && 'rgb(99, 102, 241)', border: dayStatusFilter.includes("N") && '1px solid rgb(99, 102, 241)'}}>Н</div>
                <div className={styles.modal_status_container} onClick={() => handleDayStatusFilter('O')} style={{color: dayStatusFilter.includes("O") && 'rgb(99, 102, 241)', border: dayStatusFilter.includes("O") && '1px solid rgb(99, 102, 241)'}}>О</div>
                <div className={styles.modal_status_container} onClick={() => handleDayStatusFilter('B')} style={{color: dayStatusFilter.includes("B") && 'rgb(99, 102, 241)', border: dayStatusFilter.includes("B") && '1px solid rgb(99, 102, 241)'}}>Б</div>
              </div>
            </div>
            <hr style={{margin: "9px 0"}}/>
            <div className={styles.modal_filter_status}>
              Должность
              <JobTitle jobs={jobs} choosedJob={choosedJob} addJob={addJob}  width={214}/>
            </div>
            <div className={styles.modal_filter_status} style={{marginTop: "63px"}}>
              Сотрудники
              <input className={styles.modal_filter_input} placeholder='Введите ФИО' type="text" value={nameFilter} onChange={(e) => setNameFilter(e.target.value)}/>
            </div>
          </div>} 
        </div>
      </div>
      <hr />
      <div className={styles.main_content}>
      {appointmets.map((appointmet, index) => {
          const title = appointmentKeys[index]; // Значение текущего ключа
          return (
            <Table
              index={index}
              key={title} // Уникальный ключ для React
              JobTitleName={title} // Передаем текущий ключ как название должности
              leftPart={appointmet} // Передаем данные для текущей должности
              widthPart="48px"
              canAddJobTitle={true}
              date={date}
              tableNumber={index}
              updateStatus={updateStatus}
              appointmentsdict={jobs.find((job) => job.name === title).id}
              hourNorms={hourNorms}
              jobs={jobs}
              updateData={getAllData}
              closedDay={closedDay}
            />
          );
        })}
      </div>
    </div>
  );
}

export default MainPage;