import { useRef } from 'react';
import styles from './CloseDayModal.module.css'; // Импорт стилей
import useClickOutside from '../../UseClickOutside/UseClickOutside';
import closeIcon from '../../../assets/MainPage/icon_close_daymodal.svg'
import { closeDay } from '../../../api/requests';

function CloseDayModal({seeModal, setAddEmployeeModal, date, day}) {
    const container = useRef()
    useClickOutside(container, setAddEmployeeModal);

    let closeDayFunc = async () => {
        await closeDay(`${day.toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`)
    }

    return (
        <>
            {seeModal && <div className={styles.main_container}>
                <div ref={container} className={styles.container}>
                    <div className={styles.header}>
                        <img src={closeIcon} alt="" onClick={setAddEmployeeModal}/>
                    </div>
                    <div className={styles.date_container}>
                        Будут закрыты дни вплоть 
                        до: <span>{`${day.toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`}</span> включительно
                    </div>
                    <button onClick={closeDayFunc}>Закрыть день</button>
                </div>
            </div>}
        </>
    );
}

export default CloseDayModal;
