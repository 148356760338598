import styles from './JobTitleList.module.css';
import jobtitle_arrow_icon from '../../../assets/EmployeesPage/jobtitle_arrow_icon.png'
import { useState } from 'react';

function JobTitleList({jobsEm, jobs}) {
  const [list, showList] = useState(false)

  return (
    <div className={styles.elem_jobtitles}>
        {jobsEm.length > 0 && jobs.find((el) => el.id === jobsEm[0].appointmentid).name} {jobsEm.length > 1 && 
        <>
            <div className={styles.dop_jobtitles}>+ {jobsEm.length - 1}</div>
            <img src={jobtitle_arrow_icon} onClick={() => showList(!list)} alt="" />
            
            {list && <div className={styles.list_jobtitles}>
                {jobsEm.map((item, index) => {
                    if (index !== 0) {
                        return (
                            <div key={index}>
                                {jobs.find((el) => el.id === item.appointmentid).name}
                            </div>
                        );
                    }
                })}
            </div>}
        </>}
    </div>
  );
}

export default JobTitleList;
