import React, { useEffect, useState } from 'react';
import styles from './ModalInfo.module.css';
import Calendar from './../../Calendar/Calendar';

function ModalInfo({ info, handleInfo }) {
  const [showPhone, setShowPhone] = useState(info.phone)
  const formatDate = (date) => {
      if (!date) return ''; // Если дата не задана, вернуть пустую строку
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0'); // Получаем день и добавляем ноль впереди
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Получаем месяц (0-11), добавляем 1 и добавляем ноль впереди
      const year = d.getFullYear(); // Получаем полный год

      return `${day}.${month}.${year}`; // Возвращаем отформатированную дату
  };
  const setDateFunc = (val) => {
      handleInfo('birthdate', formatDate(val));
  };

  const handlePhone = (value) => {
    if(!value) {return}
    // Удаляем все символы, кроме цифр
    const digits = value.replace(/\D/g, '');
  
    // Форматируем по шаблону "8 (___) ___ - __ - __"
    let formatted = '';

    if (digits.length >= 1) {
      formatted += '8 (' + digits.substring(1, 4);
    }
    if (digits.length >= 4) {
      formatted += ') ' + digits.substring(4, 7);
    }
    if (digits.length >= 7) {
      formatted += '-' + digits.substring(7, 9);
    }
    if (digits.length >= 9) {
      formatted += '-' + digits.substring(9, 11);
    }
  
    setShowPhone(formatted);  // Обновляем отображаемое значение
    handleInfo('phone', digits);  // Сохраняем чистый номер
  };
  
  const handleKeyDown = (e) => {
    // Проверка на нажатие клавиши Backspace
    if (e.key === 'Backspace') {
      const digits = showPhone.replace(/\D/g, '').slice(0, -1); // Убираем последнюю цифру
      handlePhone(digits); // Обновляем состояние
      e.preventDefault(); // Предотвращаем стандартное поведение
    }
  };
  useEffect(() => {
    handlePhone(info.phone)
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.container_first}>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Телефон</div>
          <input
            type="text"
            value={showPhone} // Отображаемое значение
            onChange={(e) => handlePhone(e.target.value)}
            placeholder="8 (___) ___ - __ - __"
            onKeyDown={handleKeyDown}
          />
        </div>
        
        <div className={styles.input_container}>
          <div className={styles.input_title}>Email</div>
          <input
            type="text"
            value={info.email}
            onChange={(e) => handleInfo('email', e.target.value)}
            placeholder="Email"
          />
        </div>
      </div>
      <div className={styles.container_second}>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Дата рождения</div>
          <Calendar setDateFunc={setDateFunc} isInput={true} dateFromMain={(info.birthdate !== 'NaN.NaN.NaN' && info.birthdate) || 'null'}/>
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Номер паспорта</div>
          <input
            type="text"
            value={info.passportNumber}
            onChange={(e) => handleInfo('passportNumber', e.target.value)}
            placeholder="Номер паспорта"
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Номер 1С</div>
          <input
            type="text"
            value={info.number1c}
            onChange={(e) => handleInfo('number1c', e.target.value)}
            placeholder="Номер 1С"
          />
        </div>
      </div>
    </div>
  );
}

export default ModalInfo;
